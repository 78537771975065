import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { updateHeight, updateErrors } from "actions/calorie_target.actions";
import { withinRange } from "services/validators";

import FormInput from "components/form_input";

const Height = ({
  height,
  updateHeight,
  heightErrors,
  updateErrors,
  canSetWeightAndHeight,
}) => {
  const _validate = (newHeight) => {
    if (withinRange(9, 1000, newHeight)) {
      updateErrors("height", null);
    } else {
      updateErrors("height", "Must be between 10 and 999");
    }
  };
  const validate = AwesomeDebouncePromise(_validate, 300);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    updateHeight(value);
    validate(value);
  };

  return (
    <FormInput
      name="height"
      label="Height (cm):"
      labelClass="strong"
      className="short"
      value={height || ""}
      onChange={handleChange}
      error={heightErrors}
      readOnly={!canSetWeightAndHeight}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    height: state.calorieTarget.height,
    heightErrors: state.calorieTarget.errors.height,
    canSetWeightAndHeight: state.calorieTarget.canSetWeightAndHeight,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateHeight: updateHeight,
      updateErrors: updateErrors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Height);
