import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  updateAlsfrs6Data,
  setCalorieTarget,
  setPartialCalorieTarget,
} from "actions/calorie_target.actions";

import PreStudyWeightEntry from "components/calorie_target/pre_study_weight_entry";
import Weight from "components/calorie_target/weight";
import Height from "components/calorie_target/height";
import CalorieTargetInput from "components/calorie_target/calorie_target_input";
import Alsfrs from "components/calorie_target/alsfrs";
import FormInput from "components/form_input";
import CalorieIntake from "components/calorie_target/calorie_intake";

const CalorieTargetForm = ({
  suggestedCalorieTarget,
  preStudyWeightEntries,
  readyToSubmit,
  saveCalorieTargetPath,
  readyToSubmitPartial,
  savePartialCalorieTargetPath,
  form,
  updateAlsfrs6Data,
  setCalorieTarget,
  setPartialCalorieTarget,
  canSetWeightAndHeight,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    setCalorieTarget(saveCalorieTargetPath, form);
  };

  return (
    <form
      className="pure-form pure-form-stacked form-spaced"
      onSubmit={handleSubmit}
    >
      <div id="pre-study-entries">
        {preStudyWeightEntries.length > 0 && <h2>Enter Pre-Study Weights</h2>}
        {preStudyWeightEntries.map((entry, index) => {
          return <PreStudyWeightEntry key={index} index={index} />;
        })}
      </div>

      <h2>Calculate Calorie Target</h2>
      <div className="pure-g">
        <div id="current-weight" className="pure-u-1 pure-u-lg-1-2">
          <Weight />
        </div>
        <div className="pure-u-1 pure-u-lg-1-2">
          <Height />
        </div>
      </div>

      <div className="pure-g">
        <div className="pure-u-1">
          <Alsfrs
            alsfrs6Data={form.alsfrs6Data}
            updateAlsfrs6Data={updateAlsfrs6Data}
          />
        </div>
      </div>

      <CalorieIntake />

      <div className="pure-g">
        <div className="pure-u-1 pure-u-lg-1-2">
          <FormInput
            name="suggested_calorie_target"
            label="Suggested Calorie Target:"
            labelClass="strong"
            value={suggestedCalorieTarget || "N/A"}
            readOnly={true}
          />
        </div>

        <div className="pure-u-1 pure-u-lg-1-2">
          <CalorieTargetInput />
        </div>
      </div>

      <div className="pure-g">
        <div className="pure-u-1">
          {canSetWeightAndHeight && (
            <div className="form-actions full-width">
              <button
                type="button"
                onClick={() =>
                  setPartialCalorieTarget(savePartialCalorieTargetPath, form)
                }
                className="pure-button pure-button-secondary"
                disabled={!readyToSubmitPartial}
              >
                {`Set height, weight, & calorie intake`}
              </button>
            </div>
          )}
          <div className="form-actions full-width">
            <button
              type="submit"
              className="pure-button pure-button-primary"
              disabled={!readyToSubmit}
            >
              Set calorie target
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const calorieTarget = state.calorieTarget;
  const hasPreStudyWeightErrors =
    state.preStudyWeightEntries.filter((entry) => {
      return entry.errors.weight;
    }).length != 0;
  const hasPreStudyDateErrors =
    state.preStudyWeightEntries.filter((entry) => {
      return entry.errors.date;
    }).length != 0;

  return {
    suggestedCalorieTarget: calorieTarget.suggestedCalorieTarget,
    preStudyWeightEntries: state.preStudyWeightEntries,
    readyToSubmit:
      !calorieTarget.errors.height &&
      !calorieTarget.errors.weight &&
      !calorieTarget.errors.validatedCalorieTarget &&
      Number.isFinite(calorieTarget.alsfrs6) &&
      calorieTarget.calorieTarget &&
      !calorieTarget.errors.calorieTarget &&
      !hasPreStudyWeightErrors &&
      !hasPreStudyDateErrors,
    readyToSubmitPartial:
      !calorieTarget.errors.height &&
      !calorieTarget.errors.weight &&
      !calorieTarget.errors.validatedCalorieTarget,
    saveCalorieTargetPath: calorieTarget.saveCalorieTargetPath,
    savePartialCalorieTargetPath: calorieTarget.savePartialCalorieTargetPath,
    form: {
      weight: calorieTarget.weight,
      previousWeight: calorieTarget.previousWeight,
      height: calorieTarget.height,
      bmi: calorieTarget.bmi,
      alsfrs6: calorieTarget.alsfrs6,
      alsfrs6Data: calorieTarget.alsfrs6Data,
      kasarskis: calorieTarget.kasarskis,
      validatedCalorieIntake: calorieTarget.validatedCalorieIntake,
      suggestedCalorieTarget: calorieTarget.suggestedCalorieTarget,
      calorieTarget: calorieTarget.calorieTarget,
      preStudyEntries: state.preStudyWeightEntries,
    },
    canSetWeightAndHeight: calorieTarget.canSetWeightAndHeight,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateAlsfrs6Data: updateAlsfrs6Data,
      setCalorieTarget: setCalorieTarget,
      setPartialCalorieTarget: setPartialCalorieTarget,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CalorieTargetForm);
