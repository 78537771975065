/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// We're including this file in app/views/layouts/application.html.haml - it
// should mostly only include application-wide Javascript. You should separate
// your Javascript into sensible files / components then make a decision how best
// to compose them to serve to the client.
//
// For example:
// The home page has a specific slideshow, some sort of calculator, an ad rotator etc.
// You could split these into calculator.js, slideshow.js and rotator.js then
// include them in a home.js pack which is only included on the home page.

// Initialise Sentry logging
import { Sentry } from "../components/Sentry";

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "rails-ujs";
Rails.start();

import responsiveSidebar from "scripts/responsive_sidebar";
window.addEventListener("DOMContentLoaded", (event) => {
  const sidebar = document.getElementById("sidebar");
  if (sidebar) {
    responsiveSidebar(sidebar);
  }

  document.querySelectorAll("a.under-construction").forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      alert("Under construction");
    });
  });
});

import { VisibilityMap } from "@epigenesys/epi-js";

import "whatwg-fetch";

// Stylesheets

import "purecss";
import "purecss/build/grids-responsive";

import "stylesheets/icons";
import "stylesheets/warning_page";
import "stylesheets/base_typography";
import "stylesheets/base_layout";
import "stylesheets/coverpage";
import "stylesheets/buttons";
import "stylesheets/forms";
import "stylesheets/lists";
import "stylesheets/tables";
import "stylesheets/video";
import "stylesheets/side-by-side";
import "stylesheets/status_box";
import "stylesheets/image_link";
import "stylesheets/cards";
import "stylesheets/my_resources";
import "stylesheets/quick_wins";
import "stylesheets/key_links";
import "stylesheets/inline_media";
import "stylesheets/welcome_grid";

// Support component names relative to this directory:
var componentRequireContext = require.context("pages", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
