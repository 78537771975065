import * as Sentry from "@sentry/browser";

// Notify Sentry of any js errors
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://b9c0cc8f0c284a2c8d48a9f6bd7cf846@sentry.epigenesys.org.uk/39",
  });
}

export { Sentry };
export { SentryErrorBoundary } from "./SentryErrorBoundary";
