import { Sentry } from "../../components/Sentry";
import { getCsrfToken } from "../csrf_helper";

export function getRequest({ url, params }) {
  return fetch(
    `${url}?${Object.keys(params)
      .map((key) => encodeURIComponent(key) + "=" + params[key])
      .join("&")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-csrf-token": getCsrfToken(),
      },
    }
  );
}

export function postRequest({ url, body }) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    body: JSON.stringify(body),
  });
}

export function patchRequest({ url, body }) {
  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    body: JSON.stringify(body),
  });
}

export function deleteRequest({ url }) {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
  });
}

export function processRequest(
  request,
  successFunction,
  failureFunction = () => {},
  errorFunction = (ex) => {}
) {
  return request
    .then(async (res) => {
      if (res.redirected && res.url.match(/\/users\/sign_in/)) {
        const message = "Your session has expired. Please log in again.";
        alert(message);
        errorFunction(new Error(message));
      } else if (res.status >= 400) {
        let resData = await res.json();
        failureFunction(resData);
      } else {
        let resData = await res.json();
        successFunction(resData);
      }
    })
    .catch((ex) => {
      Sentry.captureException(ex);
      errorFunction(ex);
    });
}
